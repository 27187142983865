import React, {useState, useEffect} from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
// import loadable from "@loadable/component"

import {Alert, Button, Col, Form, Row} from "react-bootstrap"

import Layout from "../components/layout"
import ReviewCard from "../components/reviewCard"

import globalStyle from "../styles/global.module.css"
import styles from "./page.module.css"

import reviews from "../markdown-content/labor-book-reviews"
import doulasStyles from "./doulas.module.css";
import formStyle from "./form.module.css";
import addToMailchimp from "../services/mailchimp";

import states from "../config/usa-states-and-cities";

// const BuyButton = loadable(() => import("../components/BuyNow"))

const MommysHelper = ({ data }) => {
  const [content, setContent] = React.useState()
  React.useEffect(() => {
    if (data.allMarkdownRemark.edges.length > 0)
      setContent(data.allMarkdownRemark.edges[0].node)
  }, [data]);


  const [showYourLaborBookNameErrorMessage, setShowYourLaborBookNameErrorMessage] = useState({ show: false, message: "" });
  const [showYourLaborBookEmailErrorMessage, setShowYourLaborBookEmailErrorMessage] = useState({ show: false, message: "" });
  const [showYourLaborBookPhoneErrorMessage, setShowYourLaborBookPhoneErrorMessage] = useState({ show: false, message: "" });
  const [showYourLaborBookStateErrorMessage, setShowYourLaborBookStateErrorMessage] = useState({ show: false, message: "" });
  const [showYourLaborBookCityErrorMessage, setShowYourLaborBookCityErrorMessage] = useState({ show: false, message: "" });
  const submitLaborBookUrl = 'https://momsonmaternity.us17.list-manage.com/subscribe/post?u=abb88870b50fd9c89b14ecf52&amp;id=c4c4fb5f4b';


  const [yourLaborBookFormState, setYourLaborFormValues] = useState({
    yourLaborBookName: "",
    yourLaborBookEmail: "",
    yourLaborBookPhone: "",
    yourLaborBookState: "",
    yourLaborBookCity: ""
  });

  const [usState, setUSState] = useState("");
  const [cities, setCities] = useState([]);

  useEffect(() => {
    populateCities()
  }, [usState])


  const selectUSState = event => {
    setYourLaborFormValues({
      ...yourLaborBookFormState,
      yourLaborBookCity: "",
    })
    setUSState(event.target.value)
    setYourLaborFormValues({
      ...yourLaborBookFormState,
      yourLaborBookState: event.target.value,
    })
    setUSState(event.target.value)
  }

  const selectCity = event => {
    setYourLaborFormValues({
      ...yourLaborBookFormState,
      yourLaborBookCity: event.target.value,
    })
  }

  const populateCities = () => {
    if (!usState) {
      setCities([])
      return
    }
    const cities = []
    for (const yourLaborBookCity of states[usState]) {
      cities.push(yourLaborBookCity)
    }
    cities.sort()
    setCities(cities)
  }

  if (!content) return null;

  const validateYourBookLaborForm = () => {
    let isValid = true;

    if (!yourLaborBookFormState.yourLaborBookName) {
      isValid = false;
      setShowYourLaborBookNameErrorMessage({
        show: true,
        message: "Please type your name!"
      })
    }
    if (!yourLaborBookFormState.yourLaborBookEmail) {
      isValid = false;
      setShowYourLaborBookEmailErrorMessage({
        show: true,
        message: "Please type your email address!"
      })
    }
    if (!yourLaborBookFormState.yourLaborBookPhone) {
      isValid = false;
      setShowYourLaborBookPhoneErrorMessage({
        show: true,
        message: "Please type your phone number!"
      })
    }
    if (!yourLaborBookFormState.yourLaborBookState) {
      isValid = false;
      setShowYourLaborBookStateErrorMessage({
        show: true,
        message: "Please select your State!"
      })
    }
    if (!yourLaborBookFormState.yourLaborBookCity) {
      isValid = false;
      setShowYourLaborBookCityErrorMessage({
        show: true,
        message: "Please select your City!"
      })
    }

    return isValid
  };


  const handleGetYourLaborBookFormChange = e => {
    let value = e.target.value;
    setYourLaborFormValues({
      ...yourLaborBookFormState,
      [e.target.name]: value,
    });
  };

  const saveLaborBookContactsToMailchimp = async () => {
    try {
      await addToMailchimp(yourLaborBookFormState.yourLaborBookEmail, {
          NAME: yourLaborBookFormState.yourLaborBookName,
          EMAIL: yourLaborBookFormState.yourLaborBookEmail,
          PHONE: yourLaborBookFormState.yourLaborBookPhone,
          STATE: yourLaborBookFormState.yourLaborBookState,
          CITY: yourLaborBookFormState.yourLaborBookCity,
        }, submitLaborBookUrl
      );
    } catch (err) {
      console.error(err);
    }
    return true;
  };

  const validateAndSaveContactFormData = async () => {
    if (validateYourBookLaborForm()) {
      await saveLaborBookContactsToMailchimp();
    }
  }

  return (
    <Layout className="site-content">
      <div className={styles.page}>
        <h1 className={globalStyle.entryTitle}>{content.frontmatter.title}</h1>

        <div className={styles.twoColumn}>
          <div className={styles.leftColumn}>
            <Img
              fluid={content.frontmatter.featuredImage.childImageSharp.fluid}
              imgStyle={{ objectFit: "contain" }}
            />

            <h3
              className={[globalStyle.entryTitle, styles.entryTitle].join(" ")}
            >
              {content.frontmatter.callToAction}
            </h3>

            <Row className={[doulasStyles.contactUsContent, "justify-content-md-center"].join(' ')}>
              <Col>
                <Form onSubmit={handleGetYourLaborBookFormChange}>
                  <Form.Row className={[doulasStyles.rowStyle, "justify-content-md-left"].join(" ")}>
                    <Col>
                      <Form.Group as={Col} controlId="yourLaborBookName" name="yourLaborBookName"
                                  style={{"paddingRight": "0px", "paddingLeft": "0px"}}
                                  className={[doulasStyles.registerFormGroup, doulasStyles.formLabel].join(' ')}>
                        <Form.Label className={[formStyle.formLabel, formStyle.required]}>Your name</Form.Label>
                        <Form.Control
                          className={doulasStyles.formInput}
                          size="sm"
                          value={yourLaborBookFormState.yourLaborBookName}
                          type="text"
                          name="yourLaborBookName"
                          placeholder="Type your name"
                          onChange={handleGetYourLaborBookFormChange}
                          onClick={() => setShowYourLaborBookNameErrorMessage({ show: false })}
                        />
                      </Form.Group>

                      { showYourLaborBookNameErrorMessage.show &&
                      <Form.Row className="justify-content-md-left">
                        <Col>
                          <Alert
                            variant="danger"
                            onClose={() => setShowYourLaborBookNameErrorMessage({ show: false })}
                            dismissible
                          >
                            {showYourLaborBookNameErrorMessage.message}
                          </Alert>
                        </Col>
                      </Form.Row>
                      }
                    </Col>
                  </Form.Row>

                  <Form.Row className={[doulasStyles.rowStyle, "justify-content-md-left"].join(" ")}>
                    <Col>
                      <Form.Group as={Col} controlId="yourLaborBookEmail" name="yourLaborBookEmail"
                                  style={{"paddingRight": "0px", "paddingLeft": "0px"}}
                                  className={[doulasStyles.registerFormGroup, doulasStyles.formLabel].join(' ')}>
                        <Form.Label className={[formStyle.formLabel, formStyle.required]}>Email address</Form.Label>
                        <Form.Control
                          className={doulasStyles.formInput}
                          size="sm"
                          value={yourLaborBookFormState.yourLaborBookEmail}
                          type="email"
                          name="yourLaborBookEmail"
                          placeholder="Type your email"
                          onChange={handleGetYourLaborBookFormChange}
                          onClick={() => setShowYourLaborBookEmailErrorMessage({ show: false })}
                        />
                      </Form.Group>

                      { showYourLaborBookEmailErrorMessage.show &&
                      <Form.Row className="justify-content-md-left">
                        <Col xs={12} sm={12} md={12} >

                          <Alert
                            variant="danger"
                            onClose={() => setShowYourLaborBookEmailErrorMessage({ show: false })}
                            dismissible
                          >
                            {showYourLaborBookEmailErrorMessage.message}
                          </Alert>
                        </Col>
                      </Form.Row>
                      }
                    </Col>
                  </Form.Row>

                  <Form.Row className={[doulasStyles.rowStyle, "justify-content-md-left"].join(" ")}>
                    <Col>
                      <Form.Group as={Col} controlId="yourLaborBookPhone" name="yourLaborBookPhone"
                                  style={{"paddingRight": "0px", "paddingLeft": "0px"}}
                                  className={[doulasStyles.registerFormGroup, doulasStyles.formLabel].join(' ')}>
                        <Form.Label className={[formStyle.formLabel, formStyle.required]}>Phone Number</Form.Label>
                        <Form.Control
                          className={doulasStyles.formInput}
                          size="sm"
                          value={yourLaborBookFormState.yourLaborBookPhone}
                          type="text"
                          name="yourLaborBookPhone"
                          placeholder="Type your phone number"
                          onChange={handleGetYourLaborBookFormChange}
                          onClick={() => setShowYourLaborBookPhoneErrorMessage({ show: false })}
                        />
                      </Form.Group>

                      { showYourLaborBookPhoneErrorMessage.show &&
                      <Form.Row className="justify-content-md-left">
                        <Col xs={12} sm={12} md={12} >

                          <Alert
                            variant="danger"
                            onClose={() => setShowYourLaborBookPhoneErrorMessage({ show: false })}
                            dismissible
                          >
                            {showYourLaborBookPhoneErrorMessage.message}
                          </Alert>
                        </Col>
                      </Form.Row>
                      }
                    </Col>
                  </Form.Row>

                  <Form.Row
                    className={[
                      doulasStyles.rowStyle,
                      "justify-content-md-left",
                    ].join(" ")}
                  >
                    <Col md={12} sm={12} xs={12}
                         style={{"paddingRight": "0px", "paddingLeft": "0px"}}>
                      <Form.Group
                        as={Col}
                        controlId="yourLaborBookState"
                        style={{"paddingRight": "0px", "paddingLeft": "0px"}}
                      >
                        <Form.Label
                          className={[
                            formStyle.formLabel,
                            formStyle.required,
                          ]}
                        >
                          State
                        </Form.Label>
                        <Form.Control
                          as="select"
                          onChange={e => {
                            selectUSState(e)
                            setShowYourLaborBookStateErrorMessage({
                              show: false,
                            })
                          }}
                          name="yourLaborBookState"
                        >
                          <option value="">
                            Select Your State
                          </option>
                          {Object.keys(states).map(
                            yourLaborBookState => (
                              <option
                                style={{
                                  background:
                                    "#5cb85c",
                                  color: "#fff",
                                }}
                                key={yourLaborBookState}
                                value={yourLaborBookState}
                              >
                                {yourLaborBookState}
                              </option>
                            )
                          )}
                        </Form.Control>
                      </Form.Group>
                    </Col>

                    <Row>
                      <Col xs={12} md={12} sm={12}>
                        {showYourLaborBookStateErrorMessage.show && (
                          <Alert
                            variant="danger"
                            onClose={() =>
                              setShowYourLaborBookStateErrorMessage(
                                { show: false }
                              )
                            }
                            dismissible
                          >
                            {
                              showYourLaborBookStateErrorMessage.message
                            }
                          </Alert>
                        )}
                      </Col>
                    </Row>
                  </Form.Row>

                  <Form.Row>
                    <Col
                      className={[
                        doulasStyles.rowStyle,
                        "justify-content-md-left",
                      ].join(" ")}
                      md={12}
                      sm={12}
                      xs={12}
                    >
                      <Form.Group
                        as={Col}
                        controlId="yourLaborBookCity"
                        style={{"paddingRight": "0px", "paddingLeft": "0px"}}
                      >
                        <Form.Label
                          className={[
                            formStyle.formLabel,
                            formStyle.required,
                          ]}
                        >
                          City
                        </Form.Label>
                        <Form.Control
                          as="select"
                          onChange={e => {
                            selectCity(e)
                            setShowYourLaborBookCityErrorMessage({
                              show: false,
                            })
                          }}
                          name="yourLaborBookCity"
                        >
                          <option value="">
                            Select Your City
                          </option>
                          {cities.map(yourLaborBookCity => (
                            <option
                              key={yourLaborBookCity}
                              value={yourLaborBookCity}
                            >
                              {yourLaborBookCity}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </Col>

                    <Col xs={12} md={12} sm={12}>
                      {showYourLaborBookCityErrorMessage.show && (
                        <Alert
                          variant="danger"
                          onClose={() =>
                            setShowYourLaborBookCityErrorMessage({
                              show: false,
                            })
                          }
                          dismissible
                        >
                          {showYourLaborBookCityErrorMessage.message}
                        </Alert>
                      )}
                    </Col>
                  </Form.Row>

                  <Button type="button"
                          className={doulasStyles.submitButton}
                          onClick={validateAndSaveContactFormData}>
                    Submit
                  </Button>
                </Form>
              </Col>
            </Row>
            {/*<div className={styles.buttonGroup}>*/}
            {/*  <BuyButton*/}
            {/*    id={"6955252744398"}*/}
            {/*    title={content.frontmatter.button1Title}*/}
            {/*    className={[*/}
            {/*      globalStyle.btnPrimary,*/}
            {/*      globalStyle.submitButtonOrange,*/}
            {/*      styles.button,*/}
            {/*      "btn",*/}
            {/*      "btn-primary",*/}
            {/*    ].join(" ")}*/}
            {/*  />*/}
            {/*  <BuyButton*/}
            {/*    id={"6955255038158"}*/}
            {/*    title={content.frontmatter.button2Title}*/}
            {/*    className={[*/}
            {/*      globalStyle.btnPrimary,*/}
            {/*      globalStyle.submitButtonOrange,*/}
            {/*      styles.button,*/}
            {/*      "btn",*/}
            {/*      "btn-primary",*/}
            {/*    ].join(" ")}*/}
            {/*  />*/}
            {/*</div>*/}
          </div>

          <div className={styles.rightColumn}>
            <div
              className={styles.content}
              dangerouslySetInnerHTML={{ __html: content.html }}
            />
            <h3 className={styles.reviewsTitle}>Here are some reviews:</h3>
            {reviews.map((e, idx) => {
              return (
                <ReviewCard key={idx} content={e.review} reviewer={e.name} />
              )
            })}
          </div>


        </div>

        <div className={styles.footnote}>{content.frontmatter.footnote}</div>
        <div className={styles.buttonGroup}>
          <Button
            className={[
              globalStyle.btnPrimary,
              globalStyle.submitButtonOrange,
            ].join(" ")}
            href={content.frontmatter.footnoteAction}
            role="button"
            target="_blank"
            rel="noopener noreferrer"
          >
            {content.frontmatter.footnoteTitle}
          </Button>
        </div>
      </div>
    </Layout>
  )
}

export default MommysHelper

export const query = graphql`
  query {
    allMarkdownRemark(filter: { frontmatter: { id: { eq: "labor-book" } } }) {
      edges {
        node {
          id
          frontmatter {
            title
            callToAction
            button1Action
            button1Title
            button2Action
            button2Title
            footnote
            footnoteAction
            footnoteTitle
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          html
        }
      }
    }
  }
`
